/* FONT PATH
 * -------------------------- */
/* red-hat-display-regular - latin-ext_latin */
@font-face {
	font-family: 'Red Hat Display';
	font-style: normal;
	font-weight: 400;
	src: url('../font-google/red-hat-display-v14-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../font-google/red-hat-display-v14-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../font-google/red-hat-display-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../font-google/red-hat-display-v14-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../font-google/red-hat-display-v14-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../font-google/red-hat-display-v14-latin-ext_latin-regular.svg#RedHatDisplay') format('svg'); /* Legacy iOS */
}
